import aayush from "./images/Aayush.jpg"
import amit from "./images/amit.jpg"
import pratik from "./images/Pratik.jpg"
import one from "./images/one.jpeg"
import two from "./images/two.jpg"
import three from "./images/three.jpg"
import four from "./images/four.jpg"
import five from "./images/five.jpg"
import six from "./images/six.jpg"
import logo from "./images/logo.jpg"
const natya_image ={
    aayush,
    amit,
    pratik,
    one,two,three,four,five,six,
    logo,
}

export default natya_image;