import React from "react";
// import Banner from "./Banner";
// import './Natyavardhan.css'
// import Mandli from "./Mandli";
// import Achievement from "./Achievement";
// import About from "./About";

function Social() {
  return (
    <div>
      {/* <Banner />
      <About />
      <Mandli />
      <Achievement /> */}
      mahesh
    </div>
  );
}

export default Social;
